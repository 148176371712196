import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useSelector, useDispatch } from 'react-redux';
import { EMPTY_ARRAY } from '../../constants';
import { getRequest } from '../../services';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/styles.css';
import RoutesComponent from './Routes';
import STORAGE_KEY from '../../constants/storageKey';
import Loader from '../../components/loader';
import CookiesPolicy from '../../components/cookiesPolicy';
import {
  getGoogleConstants,
  GOOGLE_CONSTANTS,
} from '../../formatter/google.bootstrap';
// import '../../../fonts/circular-std-font-family/CircularStd-Black.eot';
import { showSnackBar } from '../../../redux/snackBarSlice';
import ErrorSnackBar from '../../components/snackBar/ErrorSnackBar';
// import STATUS_CODE from '../../constants/statusCode';

const LoginDialog = lazy(() => import('../loginDialog'));

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const snackBarShow = useSelector((state) => state.globalSnackbar);
  const showLoginDialog = useSelector((state) => state.user.showLoginDialog);
  const dispatch = useDispatch();
  const getBootstrapData = async () => {
    try {
      const res = await getRequest('/common/bootstrap');
      if (res) {
        sessionStorage.setItem(STORAGE_KEY.BOOTSTRAP, JSON.stringify(res));
        setIsLoading(false);
      }
    } catch (error) {
      dispatch(
        showSnackBar({
          setopen: true,
          message: error.message || 'Failed to load bootstrap data.',
          severity: 'error',
        })
      );
      setIsLoading(false);
    }
  };

  useEffect(async () => {
    await getBootstrapData();
    const GOOGLE_ANALYTICS_TRACKING_ID = getGoogleConstants(
      GOOGLE_CONSTANTS.ANALYTICS
    );
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID);
  }, EMPTY_ARRAY);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(
      showSnackBar({
        setopen: false,
        message: '',
        severity: '',
      })
    );
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <RoutesComponent />
      <CookiesPolicy />
      <ErrorSnackBar opensnackbar={snackBarShow} handleClose={handleClose} />
      <Router>
        {showLoginDialog && (
          <Suspense fallback={<Loader />}>
            <LoginDialog />
          </Suspense>
        )}
      </Router>
    </>
  );
}
export default App;
