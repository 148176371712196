import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { useForm } from 'react-hook-form';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Button,
  Typography,
  TextField,
  Box,
  Link,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import URL, { employerUrls } from '../../../constants/urls';
import ErrorSnackBar from '../../../components/snackBar/ErrorSnackBar';
import STATUS_CODE from '../../../constants/statusCode';
import STORAGE_KEY from '../../../constants/storageKey';
import {
  getLoginDetailFromSession,
  setPlanDetails,
} from '../../../helpers/sessionDetails';
import { getRequest, postRequest } from '../../../services';
import REGEX_PATTERN from '../../../constants/regex';
import ERROR_MESSAGE from '../../../constants/errorMsgs';
import { API_URL, getPricePlanDetails } from '../../../constants/apiUrls';
import UI from '../../../constants/ui';
import { EMPTY_ARRAY, REDIRECT_TIME_OUT } from '../../../constants';
import Dialog from '../../../components/dialog';
import useGAEventsTracker from '../../../hooks/useGAEventsTracker';
import GA from '../../../constants/analytics.constants';

import {
  setAvailablePointsZero,
  setRequestsComplete,
  setRequestsInComplete,
  setRerenderpermissions,
  setHideLoginDialog,
  setPassword,
  setUserName,
  setContext,
} from '../../../../redux/userSlice';
import { showSnackBar } from '../../../../redux/snackBarSlice';
import { ismallMobileDevice } from '../../../hooks/useMobileDevice';
import CONTEXT_TYPE from '../../loginDialog/loginDialog.constants';
import SocailMediaLogin from '../SocailMediaLogin';
import handleLogin, { afterLogin } from './LoginHelper';
import {
  COMMON_LOGIN_STATE,
  EMPLOYER_LOGIN_ESTATE,
} from '../../../constants/loginStates.constants';

const GAEventsTracker = useGAEventsTracker(GA.EVENT_BUTTON.LOGIN);

export function requestDTO(formData, username, base64Encode) {
  return { ...formData, username, password: base64Encode };
}

function Login(props) {
  const {
    setShowNavBar,
    setLoggedInUserRole,
    setOtpTitle,
    setUserRoleOnLogout,
  } = props;
  const isLoginDialogOpen = useSelector((state) => state.user.showLoginDialog);
  const context = useSelector((state) => state.user.context);
  const isSpotOnMockInterview = sessionStorage.getItem(
    STORAGE_KEY.IS_SPOT_ON_MOCK_INTERVIEWS
  );
  const userDetail = getLoginDetailFromSession();
  const searchParams = new URLSearchParams(window.location.search);
  const expection = searchParams.get('exception');
  const [isApprovalDialogOpen, setIsApprovalDialogOpen] = useState(false);
  const [contentForApprovalDialog, setContentForApprovalDialog] = useState('');
  const [titleForApprovalDialog, setTitleForApprovalDialog] = useState('');
  const [isApprovalDialogLoading, setIsApprovalDialogLoading] = useState();
  const [isRejectedDialogOpen, setIsRejectedDialogOpen] = useState(false);
  const [contentForRejectedDialog, setContentForRejectedDialog] = useState('');
  const [isRejectedDialogLoading, setIsRejectedDialogLoading] = useState();
  const [opensnackbar, setSnackbarOpen] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const isSmallDevice = ismallMobileDevice();
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen({ setopen: false });
  };

  useEffect(() => {
    setShowNavBar(true);
  }, EMPTY_ARRAY);

  useEffect(() => {
    if (expection) {
      dispatch(
        showSnackBar({
          setopen: true,
          message: expection,
          severity: 'error',
          duration: 5000,
        })
      );
    }
  }, [expection]);

  function checkProjectProperty(resolve) {
    const intervalId = setInterval(() => {
      const loginDetail = getLoginDetailFromSession();
      if (loginDetail && loginDetail.project) {
        clearInterval(intervalId);
        resolve(dispatch(setRequestsComplete()));
        dispatch(setRerenderpermissions());
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }

  const getPlanDetails = (planid) => {
    getRequest(getPricePlanDetails(planid))
      .then((res) => {
        setPlanDetails(res);
      })
      .catch((err) => {
        setSnackbarOpen({
          setopen: true,
          message: err.msg,
          severity: 'error',
        });
      });
  };

  const logoutApi = () => {
    postRequest(API_URL.LOGOUT)
      .then((res) => {
        if (res.code === STATUS_CODE.SUCCESSFULLY_LOGOUT) {
          localStorage.removeItem(STORAGE_KEY.CANDIDATE_DETAILS);
          localStorage.removeItem(STORAGE_KEY.EMPLOYER_DETAILS);
          localStorage.removeItem(STORAGE_KEY.SESSION_DETAILS);
          localStorage.removeItem(STORAGE_KEY.PRICE_PLAN_DETAILS);
          navigate(URL.HOME);
          setUserRoleOnLogout();
          dispatch(setRequestsInComplete());
          dispatch(setRerenderpermissions());
          dispatch(setAvailablePointsZero());
          dispatch(setHideLoginDialog());
        }
      })
      .catch(() => {});
  };

  const handleEmployerLogin = () => {
    if (
      userDetail?.estate === EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING ||
      userDetail?.state === COMMON_LOGIN_STATE.INACTIVE
    ) {
      if (userDetail?.estate === EMPLOYER_LOGIN_ESTATE.APPROVAL_PENDING) {
        setTitleForApprovalDialog(UI.ACCOUNT_APPROVAL_PENDING);
        setContentForApprovalDialog(UI.ACCOUNT_APPROVAL_PENDING_MSG);
      } else {
        setTitleForApprovalDialog(UI.ACCOUNT_INACTIVE);
        setContentForApprovalDialog(UI.ACCOUNT_INACTIVE_MSG);
      }
      setIsApprovalDialogLoading(true);
      setIsApprovalDialogOpen(true);
      setIsApprovalDialogLoading(false);
    } else if (userDetail?.estate === EMPLOYER_LOGIN_ESTATE.REJECTED) {
      setIsRejectedDialogLoading(true);
      setContentForRejectedDialog(UI.ACCOUNT_REJECTED_MSG);
      setIsRejectedDialogOpen(true);
      setIsRejectedDialogLoading(false);
    } else {
      afterLogin({
        isLoginDialogOpen,
        isSpotOnMockInterview,
        navigate,
        checkProjectProperty,
        getPlanDetails,
        GAEventsTracker,
        dispatch,
        context,
      });
    }
  };

  const onClickBtn = (data) => {
    const base64Encode = btoa(data.password);
    const username = data.username.toLowerCase();
    dispatch(setPassword(data.password));
    dispatch(setUserName(username));
    const requestPayload = requestDTO(data, username, base64Encode);
    const requestPayloads = new URLSearchParams(requestPayload);
    const finalPayload = requestPayloads.toString();
    setIsLoading(true);
    axios
      .post(API_URL.LOGIN, finalPayload, {
        withCredentials: true,
        headers: {
          'content-type': 'application/x-www-form-urlencoded',
        },
      })
      .then((res) => {
        setSnackbarOpen({
          setopen: true,
          message: res?.data?.msg,
          severity: 'success',
        });

        // dispatch(
        //   showSnackBar({
        //     setopen: true,
        //     message: 'res?.data?.msg',
        //     severity: 'success',
        //     duration: 10000
        //   })
        // );
        if (res.data.code === STATUS_CODE.SUCCESSFULLY_LOGIN) {
          setIsLoading((prev) => !prev);
          handleLogin({
            isLoginDialogOpen,
            setLoggedInUserRole,
            dispatch,
            GAEventsTracker,
            isSpotOnMockInterview,
            checkProjectProperty,
            navigate,
            getPlanDetails,
            handleEmployerLogin,
            context,
          });
          setIsLoading((prev) => !prev);
        }
      })
      .catch((error) => {
        dispatch(
          showSnackBar({
            setopen: true,
            message: error.response?.data?.msg,
            severity: 'error',
            duration: 3000,
          })
        );
        if (
          error.response.data.code ===
          STATUS_CODE.RESEND_EMAIL_OF_DISABLE_ACCOUNTS
        ) {
          setTimeout(() => {
            navigate(`${URL.VERIFY_EMAIL}?email=${data.username}`);
          }, REDIRECT_TIME_OUT);
        } else if (
          error.response.data.code === STATUS_CODE.RESEND_OTP_OF_DISABLE_ACCOUNT
        ) {
          if (!isLoginDialogOpen) {
            setTimeout(() => {
              navigate(`${URL.REGISTER}?otp=true&email=${data.username}`);
            }, REDIRECT_TIME_OUT);
          }
          if (isLoginDialogOpen) {
            setOtpTitle(true);
            dispatch(setContext(CONTEXT_TYPE.REGISTER_FORM));
          }
        }
      })
      .finally(() => setIsLoading(false));
  };
  const [values, setValues] = useState({
    password: '',
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    const password = event.target.value;
    setValues({ ...values, [prop]: password });
    setValue('password', password);
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleApprovalDialogClose = () => {
    setIsApprovalDialogOpen(false);
    afterLogin(
      isLoginDialogOpen,
      isSpotOnMockInterview,
      navigate,
      checkProjectProperty,
      getPlanDetails,
      dispatch,
      context
    );
  };

  const handleRejectedDialogClose = () => {
    setIsRejectedDialogOpen(false);
    logoutApi();
  };

  const renderSocailMediaLogin = () => {
    if (isLoginDialogOpen && !employerUrls.has(window.location.pathname)) {
      return <SocailMediaLogin />;
    }
    if (!isLoginDialogOpen) {
      return <SocailMediaLogin />;
    }
    return null;
  };

  return (
    <div className={`${isSmallDevice && 'p-3'}`}>
      <Dialog
        isDialogOpen={isApprovalDialogOpen}
        onDialogClose={handleApprovalDialogClose}
        title={titleForApprovalDialog}
        content={contentForApprovalDialog}
        isLoading={isApprovalDialogLoading}
        primaryLabel={UI.OK}
        primaryAction={handleApprovalDialogClose}
      />
      <Dialog
        isDialogOpen={isRejectedDialogOpen}
        onDialogClose={handleRejectedDialogClose}
        title={UI.ACCOUNT_REJECTED}
        content={contentForRejectedDialog}
        isLoading={isRejectedDialogLoading}
        primaryLabel={UI.OK}
        primaryAction={handleRejectedDialogClose}
      />
      <div
        className={`card container ${isSmallDevice ? 'p-3' : 'p-4'} mt-4`}
        style={{
          boxShadow: '0px 0px 3px #48B2FF29',
          backgroundColor: '#fff',
          borderRadius: '8px',
          maxWidth: 350,
        }}
      >
        <form onSubmit={handleSubmit(onClickBtn)} autoComplete="on">
          <div className="headline-5 mb-3">{UI.LOGIN}</div>
          <div className="my-2">{UI.EMAIL}</div>
          <TextField
            {...register('username', {
              required: ERROR_MESSAGE.REQ_ERROR_MSG,
              pattern: {
                value: REGEX_PATTERN.EMAIL_FORMAT,
                message: ERROR_MESSAGE.EMAIL_VALIDATION,
              },
              onChange: (event) => {
                setValue('username', event.target.value.toLowerCase());
              },
            })}
            fullWidth
            type="email"
            id="username"
            variant="outlined"
            size="small"
            placeholder={UI.ENTER_EMAIL}
            name="username"
            autoComplete="username"
          />

          {errors.username && (
            <Box>
              <span className="mandatory">{errors.username.message}</span>
            </Box>
          )}
          <div className="my-2">{UI.PASSWORD}</div>
          <OutlinedInput
            {...register('password', {
              required: ERROR_MESSAGE.REQ_ERROR_MSG,
            })}
            fullWidth
            id="password"
            variant="outlined"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            placeholder={UI.ENTER_PASSWORD}
            name="password"
            size="small"
            autoComplete="current-password"
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {errors.password && (
            <Box>
              <span className="mandatory">{errors.password.message}</span>
            </Box>
          )}
          <Typography sx={{ mb: 2 }}>
            <Link
              // sx={{ color: 'gray' }}
              as={NavLink}
              to={URL.FORGOT_PASSWORD}
              underline="none"
            >
              <span className="subtitle-2 color-1D8FF2">
                {UI.FORGOT_PASSWORD}
              </span>
            </Link>
          </Typography>

          <Button
            fullWidth
            size="large"
            variant="contained"
            type="submit"
            sx={{ mb: 1 }}
            disabled={isLoading}
            startIcon={
              isLoading && <CircularProgress size="1rem" color="inherit" />
            }
          >
            {UI.LOGIN}
          </Button>
        </form>
        {/* <Box component="div" textAlign="center">
                Or
              </Box>
              <Box component="div">
                <Button
                  fullWidth
                  startIcon={<GoogleIcon />}
                  size="large"
                  variant="outlined"
                  type="submit"
                  sx={{ color: 'gray', mt: 2,  }}
                >
                  Login with Google
                </Button>
              </Box>

              <Button
                fullWidth
                startIcon={<LinkedInIcon />}
                size="large"
                variant="outlined"
                type="submit"
                sx={{ color: 'gray', mt: 2,  }}
              >
                Login with LinkedIn
              </Button> */}
        {renderSocailMediaLogin()}
      </div>

      {isLoginDialogOpen ? (
        <Typography sx={{ color: 'gray', mt: 1, textAlign: 'center' }}>
          {UI.NEW_TO_COMPANY_IN}&nbsp;
          <span
            style={{ color: '#1976d2', cursor: 'pointer' }}
            onClick={() => {
              dispatch(setContext(CONTEXT_TYPE.REGISTER_FORM));
            }}
          >
            {UI.REGISTER_FOR_FREE}
          </span>
        </Typography>
      ) : (
        <Typography sx={{ color: 'gray', mx: 4, mt: 1, textAlign: 'center' }}>
          {UI.NEW_TO_COMPANY_IN}&nbsp;
          <Link
            sx={{ color: '#1976d2' }}
            as={NavLink}
            to={URL.REGISTER}
            underline="none"
          >
            {UI.REGISTER_FOR_FREE}
          </Link>
        </Typography>
      )}
      <ErrorSnackBar opensnackbar={opensnackbar} handleClose={handleClose} />
    </div>
  );
}

Login.propTypes = {
  setShowNavBar: PropTypes.func,
  setLoggedInUserRole: PropTypes.func,
  setUserRoleOnLogout: PropTypes.func,
  onDialogClose: PropTypes.func,
  setShowRegisterDialog: PropTypes.func,
  setOtpTitle: PropTypes.func,
};

Login.defaultProps = {
  setShowNavBar: noop,
  setLoggedInUserRole: noop,
  setUserRoleOnLogout: noop,
  onDialogClose: noop,
  setShowRegisterDialog: noop,
  setOtpTitle: noop,
};

export default Login;
