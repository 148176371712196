export const EMPTY_ARRAY = Object.freeze([]);
export const EMPTY_OBJECT = Object.freeze({});
export const OTHER_SALARY_ID = 1000000;
export const HEAR_OTHER_ID = 1000000;
export const FILTER_FIELDS_NOT_DISPLAY = 1000000;
export const DEFAULT_SKILL_ID = -1;
export const DEFAULT_COLLEGE_ID = -1;
export const DEFAULT_CERTIFICATE_ID = -1;
export const DEFAULT_ASSESSMENT_ID = -1;
export const DEFAULT_COMPANY_ID = -1;
export const DEFAULT_DOMAIN_ID = -1;
export const DEFAULT_JOB_TITLE_ID = -1;
export const DEFAULT_DESIGNATION_ID = -1;
export const DEFAULT_TAG_ID = -1;
export const DEFAULT_CONTEXT_ID = -1;
export const FRESHER_ID = -1;
export const EXP_FRESHER_ID = 100;
export const REMOTE_100_ID = 5;
export const REMOTE_ID = 4;
export const DEFAULT_RATING = -1;
export const DEFAULT_COMPLETE_ASSESSMENT_STATE = -1;
export const DEFAULT_RATING_NAME = 'Any';
export const DEFAULT_SELECTED_PAGE = 1;
export const FIRST_QUESTION_NUMBER = 1;
export const DEFAULT_COUNT = -1;
export const SALARY_AS_PER_INDUSTRY_STANDARD = -1;
export const RATING_BEGINNER = 100;
export const EMP_STATE = 1;
export const PAGINATION_ROWS = 10;
export const PAGINATION_ROWS_12 = 12;
export const REDIRECT_TIME_OUT = 3000;
export const REDIRECT_TIME_OUT_FOR_VIEW_COMPONENTS = 5000;
export const GST = 18;
export const FULL_TIME_JOB = 300;
export const INTERNSHIP_ID = 350;
export const EMPTY_DOMAIN_ID = -2;
export const EMPTY_TAG_ID = -2;
export const TAG_MAX_LENGTH = 32;
export const TAGS_DISPLAY_LENGTH_FILTER = 5;
export const DEFAULT_RATING_VALUE = 5;
export const DEFAULT_STUDENT_EXP = -2;
