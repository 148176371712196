const STATUS_CODE = {
  SUCCESSFULLY_LOGIN: 158,
  SUCCESSFULLY_LOGOUT: 166,
  USRE_NOTFOUND: 140,
  INVALID_TOKEN: 141,
  EXPIRE_TOKEN: 142,
  PASSWORD_RESET_MAIL_SENT: 149,
  CONFIRM_TOKEN_SUCCESS: 143,
  INVALID_PASSWORD_RESET_TOKEN: 146,
  CONFIRM_RESEND_OLD_TOKEN_SUCCESS: 150,
  UPDATE_PASSWORD_SUCCESSFULLY: 152,
  RESEND_EMAIL_OF_DISABLE_ACCOUNTS: 154,
  INVALID_CREDENTIALS: 153,
  SAVE_A_NEW_PASSWORD: 148,
  AUTH401: 401,
  USER_NOT_LOGGED_IN: 108,
  FEEDBACK_SUBMITTED_SUCCESSFULLY: 194,
  EXCEEDED_ATTEMPTS_TO_GENERATE_TOKEN_FOR_PASSWORD_RESET: 159,
  PREREQUISITE_FAILED_SAVE: 274,
  PREREQUISITE_FAILED: 284,
  EMPLOYER_APPROVAL_PENDING: 208,
  EMPLOYER_APPROVAL_REJECTED: 209,
  EMPLOYER_INACTIVE: 213,
  NORMAL_USER_ACCOUNT_DELETE: 258,
  OWNER_ACCOUNT_DELETE: 257,
  COMPANY_ACCOUNT_DELETE: 256,
  RESEND_OTP_OF_DISABLE_ACCOUNT: 287,
  RATE_LIMITING: 429,
};
export default STATUS_CODE;
